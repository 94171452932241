@charset "utf-8";
/* TOC

- Adjustments: Video Layout
- Navigation
- Search
- Masthead
- Masthead › small-only
- Masthead › medium-only
- Masthead › large-only
- Masthead › xlarge-up
- Breadcrumb
- Meta
- Jump to top
- Footer
- Subfooter
- CSS-Classes to add margin at top or bottom

*/



/* Adjustments: Video Layout
------------------------------------------------------------------- */

body.video,
body.video #masthead-no-image-header { background: #000; }
body.video #masthead-no-image-header { margin-bottom: 60px; }
body.video h1,
body.video h2,
body.video h3,
body.video h4,
body.video h5,
body.video h6,
body.video p,
body.video a,
body.video blockquote:before,
body.video blockquote:after,
body.video cite a, { color: #fff; }
body.video cite a:visited, { color: #fff; }
body.video cite { color: #fff; }



/* Navigation
------------------------------------------------------------------- */

#navigation {
    -webkit-box-shadow: 0 2px 2px 0 $black;
    box-shadow: 0 2px 3px 0 $black;
}

.lang {
    position: absolute;
    top: 12px;
    right: 125px;

    @media screen and (max-width: 640px) {
        right: 60px;
    }
}

.menu-text {
    display: inline-block;

    @media screen and (max-width: 640px) {
        display: none;
    }
}



/* Search
------------------------------------------------------------------- */

.no-js form#search {
  display: none;
}



/* Masthead
------------------------------------------------------------------- */

#masthead {
    background-color: $primary-color;
}
#masthead-no-image-header {
    background-color: $primary-color;
}
#masthead-with-text {
    text-align: center;
    font-size: rem-calc(54);
    font-family: $header-font-family;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 3px rgba(0,0,0,.4);
}
#masthead-no-image-header {
    height: 175px;
}
#masthead-no-image-header #logo img {
    margin-top: 60px;
}

.masthead-home {
    .masthead-title {
        background-color: rgba(0,0,0,0.75);
        border: 1px solid #999;
        border-radius: 5px;
        box-shadow: 0px 0px 7px #aaa;
        width: auto;
        margin: auto;
        display: inline-block;
        padding: 40px 80px;
    }
    .home-header-title {
        font-size: 2.25em;
        line-height: 1;
        margin: 0;
        font-family: 'earthnormal', Georgia, 'Times New Roman', Times, serif;
        text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
        @media #{$small-only} {
            font-size: 1.15em;
        }
        @media #{$medium-only} {
            font-size: 1.6em;
        }
    }
    .home-header-sponsor {
        flex-direction: row;
        .home-header-spanSponsor {
            display: inline-block;
            font-size: 1.35rem;
            margin-left: 2vw;
            margin-right: 2vw;
        }
        .home-header-imgSponsor {
            max-width: 400px;
        }
    }
    .dates2021 {
        .large-5 {
            text-align: right;
        }
        .large-7 {
            text-align: left;
        }
    }
    .home-header-year {
        line-height: 1;
        margin: 0;
        font-family: 'earthnormal', Georgia, 'Times New Roman', Times, serif;
    }
    .home-header-dates {
        line-height: 1;
        p {
            margin: 0;
        }
    }
    .home-header-tldr {
        line-height: 2;
        p {
            margin: 0;
        }
    }
}

@media only screen and (max-width: 360px) {
    .masthead-home {
        .home-header-title {
            font-size: 0.85em;
        }
    }

}

/* Masthead › small-only
------------------------------------------------------------------- */

@media #{$small-only} {
    #logo img {
        display: none;
    }
    #masthead {
        height: 200px;
    }
    #masthead-with-pattern {
        padding: 15px 0;
    }
    #masthead-with-background-color {
        padding: 15px 0;
    }
    #masthead-with-text {
        height: 220px;
        padding: 30px 0;
        font-size: rem-calc(36);
    }
    #masthead-no-image-header {
        display: none;
    }
    .newsbox {
        height: 125px;
    }
    .masthead-home {
        height: calc(180vh - 40px) !important;
        padding: 60px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .dates2021 {
            margin-bottom: 5vh;
            display: flex;
            flex-direction: column;
            .large-5, .large-7 {
                text-align: center;
            }
        }
        .home-header-sponsor {
            flex-direction: column;
            .home-header-spanSponsor {
                font-size: 0.85rem;
            }
            .home-header-imgSponsor {
                max-width: 300px;
            }
        }
    }
}


/* Masthead › medium-only
------------------------------------------------------------------- */

@media #{$medium-only} {
    #logo img {
        margin-top: 60px;
    }
    #masthead {
        height: 280px;
    }
    #masthead-with-pattern {
        padding: 20px 0;
    }
    #masthead-with-background-color {
        padding: 20px 0;
    }
    #masthead-with-text {
        padding: 60px 0;
        height: 300px;
    }
    .newsbox {
        height: 150px;
    }
    .masthead-home {
        height: calc(120vh - 50px) !important;
        padding: 60px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


/* Masthead › large-only
------------------------------------------------------------------- */

@media #{$large-only} {
    #logo img {
        margin-top: 80px;
    }
    #masthead {
        height: 310px;
    }
    #masthead-with-pattern {
        padding: 30px 0;
    }
    #masthead-with-background-color {
        padding: 30px 0;
    }
    #masthead-with-text {
        height: 330px;
        padding: 60px 0;
    }
    .newsbox {
        height: 85px;
    }
    .masthead-home {
        height: calc(120vh - 50px) !important;
        padding: 60px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


/* Masthead › xlarge-up
------------------------------------------------------------------- */

@media #{$xlarge-up} {
    #logo img {
        margin-top: 110px;
    }
    #masthead {
        height: 380px;
    }
    #masthead-with-pattern {
        padding: 45px 0;
    }
    #masthead-with-background-color {
        padding: 45px 0;
    }
    #masthead-with-text {
        padding: 95px 0;
        height: 300px;
    }
    .newsbox {
        height: 85px;
    }
    .masthead-home {
        height: calc(120vh - 50px) !important;
        padding: 60px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


#title-image-small {
    height: 240px;
}
#title-image-large {
    height: 520px;
}
#title-image-index-small {
    height: 120px;
}
#title-image-index-large {
    height: 260px;
}



/* Breadcrumb
------------------------------------------------------------------- */

#breadcrumb {
    background: scale-color($grey-1, $lightness: 55%);
    border-top: 1px solid scale-color($grey-1, $lightness: 45%);
    border-bottom: 1px solid scale-color($grey-1, $lightness: 45%);
}
.breadcrumbs>.current {
  font-weight: bold;
}


/* Meta
------------------------------------------------------------------- */

#page-meta, #page-meta a {
    color: $grey-5;
}

#page-meta .button {
    background: $grey-5;
    border: 0;
}
#page-meta .button {
    color: #fff;
}
#page-meta .button:hover {
    background: $primary-color;
}
.meta-info p {
  font-size: rem-calc(13);
  color: scale-color($grey-1, $lightness: 40%);
}
  .meta-info a {
    text-decoration: underline;
    color: scale-color($grey-1, $lightness: 40%);
  }
  .meta-info a:hover {
    text-decoration: none;
    color: $secondary-color;
  }



/* Jump to top
------------------------------------------------------------------- */

#up-to-top {
    padding: 160px 0 10px 0;
}
#up-to-top a {
    font-size: 24px;
    padding: 5px;
    border-radius: 3px;
}
#up-to-top a:hover {
    background: $grey-2;
}



/* Footer
------------------------------------------------------------------- */

#footer-content p,
#footer-content li {
    font-size: rem-calc(13);
    font-weight: 300;
}

#footer {
    padding-top: 30px;
    padding-bottom: 20px;
    background: $footer-bg;
    color: $footer-color;
    }

    #footer a {
        color: $footer-link-color;
    }
    #footer h4,
    #footer h5 {
        letter-spacing: 1px;
        color: #fff;
        text-transform: uppercase;
    }



/* Subfooter
------------------------------------------------------------------- */

#subfooter {
    background: $subfooter-bg;
    color: $subfooter-color;
    padding-top: 30px;
}

#subfooter-left ul.inline-list {
    float: left;
}

.credits a {
    color: $subfooter-link-color;
    border: 0;
    text-transform: uppercase;
    &:hover {
        color: #fff;
  }
}

.social-icons {
    margin-bottom: 10px !important;

// Beware of SCSS-Syntax here
  li {
    padding: 0 0 20px 0;
  }
  a {
    font-size: rem-calc(23);
    display: block;
    width: 36px;
    border-radius: 50%;
    color: $subfooter-bg;
    background: $subfooter-color;
    text-align: center;
    &:hover {
      background: $subfooter-bg;
      color: #fff;
    }
  }
}



/* CSS-Classes to add margin at top or bottom
------------------------------------------------------------------- */

.t10 { margin-top: 10px !important; }
.t15 { margin-top: 15px !important; }
.t20 { margin-top: 20px !important; }
.t30 { margin-top: 30px !important; }
.t50 { margin-top: 50px !important; }
.t60 { margin-top: 10px !important; }
.t70 { margin-top: 70px !important; }
.t80 { margin-top: 80px !important; }
.t90 { margin-top: 90px !important; }

.b15 { margin-bottom: 15px !important; }
.b20 { margin-bottom: 20px !important; }
.b30 { margin-bottom: 30px !important; }
.b60 { margin-bottom: 10px !important; }

.l15 { margin-left: 15px !important; }
.r15 { margin-right: 15px !important; }

.pl20 { padding-left: 20px !important; }
.pr5  { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr20 { padding-right: 20px !important; }
